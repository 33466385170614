import React from "react";
import "./styles/index.css";
// import App from "./App";
import reportWebVitals from "./reportWebVitals";

const run = async (): Promise<void> => {
    // dynamic imports for code splitting
    const { lazy, Suspense } = await import("react");
    const ReactDOM = await import("react-dom");
    const { BrowserRouter } = await import("react-router-dom");
    await import("./__global");
    const App = lazy(() => import("./AppNoWallet"));

    ReactDOM.render(
        <React.StrictMode>
            <Suspense fallback={<div />}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Suspense>
        </React.StrictMode>,
        document.getElementById("root")
    );
};

void run();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
